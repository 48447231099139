import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { PagesRoutes } from "@/types";

import { AsignarMesa, ListaEspera, ProtectedRoute, Root, ValidarOTP } from "@/views";
import { ListaEsperaLayout } from "@/context";

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route Component={Root}>
			<Route path={`${PagesRoutes.ValidarOTP}/:otp`} lazy={ValidarOTP} />

			<Route lazy={ProtectedRoute}>
				<Route lazy={ListaEsperaLayout}>
					<Route path={PagesRoutes.ASIGNAR_MESAS} lazy={AsignarMesa} />

					{/* Deprecada */}
					<Route path={PagesRoutes.LISTA_ESPERA} lazy={ListaEspera} />
				</Route>

				<Route path='*' Component={() => <Navigate to={PagesRoutes.ASIGNAR_MESAS} />} />
			</Route>

		</Route>
	)
);
