import Root from './Root';

const AsignarMesa = () => import('./AsignarMesa');
const ValidarOTP = () => import('./ValidarOTP');
const ProtectedRoute = () => import('./ProtectedRoute');

// Deprecada
const ListaEspera = () => import('./ListaEspera');

export {
	AsignarMesa,
	ListaEspera,
	Root,
	ProtectedRoute,
	ValidarOTP
}

